import React, { useState } from 'react';
import { BASE_SCAN_EVENT, CHANNEL_ID, CONTRACT_ADDRESS, COUNTRY_FLAGS, X_API_KEY } from '../constants';
import { useNavigate } from 'react-router-dom';
import Web3 from 'web3';


export function simplifyFraction(numerator, denominator) {
  if (denominator === 0) {
    return 1;
  }

  function gcd(a, b) {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  }

  const greatestCommonDivisor = gcd(numerator, denominator);

  const simplifiedNumerator = numerator / greatestCommonDivisor;
  const simplifiedDenominator = denominator / greatestCommonDivisor;

  const result = simplifiedNumerator / simplifiedDenominator;
  return result % 1 === 0 ? result.toString() : result.toFixed(2);
}

const BetCard = ({ 
  country, 
  totalBets,
  betAmount, 
  balance,
  country_val,
  matchId,
  token,
  account,
  approved,
  setApproved, 
  minstake,
  maxstake,
  contract,
  connectWallet,
  totalJackpotAmount
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedButtonData, setClickedButtonData] = useState({});
  const [betInputAmount, setBetInputAmount] = useState(0);
  const [inputError, setInputError] = useState("");
  const navigate = useNavigate();

  const handleBetAmountChange = (e) => {
    setBetInputAmount(e.target.value);
  };

  const potentialOutcome = betInputAmount ? (clickedButtonData.fraction * betInputAmount) : 0;

  const handleClick = ({ fraction, country_val,  country, totalBets }) => {
    setClickedButtonData({
      fraction,
      country_val,
      country,
      totalBets,
    });
    setIsModalOpen(true);
    setBetInputAmount("");
    setInputError("");
  };

  const handlePlaceBet = async () => {
    const value = Number(betInputAmount);
    if (Number(balance) === 0  || value > Number(balance)) {
      setInputError("Please check your balance.");
    } else if (value < minstake || value > maxstake) {
      setInputError(`Please enter a valid amount between ${minstake} and ${maxstake}`);
    } else if (account) {
      // Place bet logic here
      try {
        // const betAmountInTokens = Web3.utils.toWei(value, "ether"); // Converts the amount to 18 decimals
        await contract.methods
          .placeStake(matchId, clickedButtonData.country_val, value)
          .send({ from: account });

          const payload = {
            channel_id: CHANNEL_ID,
            account: account,
            match_id: matchId,
            team_id: clickedButtonData.country_val,
            team_name: clickedButtonData.country,
            amount: value,
            match: 'EURO CUP TOURNAMENT',
            message: "[BET PLACED SUCCESSFULLY]"
          };
          
        // Send an event after succefully placing the bet 
        try {
          const initialResponse = await fetch(BASE_SCAN_EVENT, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              'x-api-key': X_API_KEY
            },
            body: JSON.stringify(payload)
          });

          // Check for fetch errors
          if (!initialResponse.ok) {
            throw new Error(`Error sending initial event: ${initialResponse.statusText}`);
          } else {
            console.log({ res: initialResponse });
          }
        } catch (fetchError) {
          console.error("Error sending event to eventAPI:", fetchError);
        }
      

        alert(`Bet placed: ${value} on ${clickedButtonData.country}`);
        navigate(0);
      } catch (error) {
        console.error("Error placing bet:", error);
        alert("Error placing bet!");
      }
      setIsModalOpen(false);
    }
  };

  const handleapprove = async () => {
    if (account && token) {
      try {
        const maxAmountInTokens = Web3.utils.toWei(1000000, "ether");
        await token.methods
          .approve(CONTRACT_ADDRESS, maxAmountInTokens)
          .send({ from: account });
        setApproved(true);
        localStorage.setItem("walletApproved", "true");
        alert("Staking approved successfully!");
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error approving stake:", error);
        alert("Error approving stake!");
      }
    }
  };

  return (
    <>
      <button 
        className="bg-[#061D4BBF] rounded-lg bg-opacity-75 p-4 flex flex-col items-center justify-center text-white"
        onClick={() => handleClick({fraction: simplifyFraction(totalJackpotAmount, betAmount), country: country, country_val: country_val, totalBets: totalBets})}
      >
        <div className='flex justify-between items-center w-[100%]'>
          <div className="text-sm font-secondary mb-2">Total Bets ( {totalBets} )</div>
          <div className="text-lg  mb-2">FENS {betAmount}</div>
        </div>
        <div className='flex items-center justify-between w-full'>
          <div className='flex gap-2 w-[100%]'>
            <img src={COUNTRY_FLAGS[country?.toLowerCase()]} alt={country} className="w-12 h-12 mb-2" />
            <div className="text-lg mt-2 font-primary">{country}</div>
          </div>
          <div className='text-lg mt-3 font-primary h-full'>{simplifyFraction(totalJackpotAmount, betAmount)}x</div>
        </div>
      </button>

      {isModalOpen ?
        (
          <div className='fixed inset-0 flex items-center justify-center p-16'>
            <div className='bg-[#122243] p-4 rounded-lg shadow-lg relative lg:min-w-[450px] md:min-w-[450px]'>
              <div className='flex justify-between border-[#0B1428] border-b-2 pb-2'>
                <div className='flex gap-2 space-x-4'>
                  <img
                    src={COUNTRY_FLAGS[clickedButtonData?.country.toLowerCase()]}
                    alt='country-flag'
                    className='rounded-full '
                  />
                  <div className='flex flex-col justify-center'>
                    <p className='mt-0 text-xl text-white uppercase'>
                      {clickedButtonData.country}
                    </p>
                    <p className='mt-0 text-base text-[#8991A2] capitalize font-secondary'>
                      Tournament Winner
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className='text-3xl text-gray font-secondary'
                >
                  X
                </button>
              </div>
              <div className='flex justify-between items-center py-2 '>
                <span className='font-secondary text-gray text-lg'>
                Reward Pool:{" "} 
                </span>
                <span className='font-primary'>
                  ${betAmount?.toFixed(2)}
                </span>
              </div>
              <div className='flex justify-between'>
                <p className='flex space-x-2'>
                  <span>{clickedButtonData.fraction}x</span>
                  <span className='font-secondary text-gray text-lg'>
                    Winnings
                  </span>{" "}
                </p>
                <p className='flex space-x-2'>
                  <span className='font-secondary text-gray text-lg'>BET(s)</span>{" "}
                  <span> {clickedButtonData.totalBets}</span>
                </p>
              </div>
              <p className='mt-2 text-sm text-gray-500'></p>
              <input
                // type='number'
                value={betInputAmount}
                onChange={handleBetAmountChange}
                placeholder='enter amount'
                in
                className='mt-2 w-full text-lg  font-semibold font-secondary border border-gray-300 rounded-lg p-2 text-[#00BDE5] bg-[#0B1428] placeholder-green outline-none'
              />

              <div className='flex justify-end  py-2 space-x-3'>
                <span className='font-secondary text-gray text-xl'>Balance</span>{" "}
                <span>$ {balance ? balance : "0"}</span>
              </div>
              {inputError && (
                <p className='mt-2 text-sm text-red-500'>{inputError}</p>
              )}
              <div className='mt-4 flex justify-between'>
                <p className='mt-2 flex flex-col'>
                  <span className='font-secondary text-gray text-lg'>
                    {" "}
                    Potential Outcome:
                  </span>
                  <span className=''> $ {potentialOutcome}</span>
                </p>
                {account ? (
                  !approved ? (
                    <button
                      className='bg-[#00BDE5] font-secondary min-w-44 text-xl font-bold px-4 py-2 text-stroke text-white rounded-lg'
                      onClick={handleapprove}
                    >
                      Approve
                    </button>
                  ) : (
                    <button
                      className='bg-[#00BDE5] font-secondary min-w-44 text-xl font-bold px-4 py-2 text-stroke text-white rounded-lg'
                      onClick={handlePlaceBet}
                    >
                      Bet !!!!
                    </button>
                  )
                ) : (
                  <button
                    className='bg-[#00BDE5] font-secondary  min-w-44 text-lg px-4 py-2 text-stroke text-white rounded-lg'
                    onClick={connectWallet}
                  >
                    {!account ? "CONNECT WALLET" : " DISCONNECT "}
                  </button>
                )}
              </div>
            </div>
          </div>
        ): null}
    </>
  );
};

export default BetCard;
