import React, { useState } from "react";
import CardGrid from "./Cardgrid";
import Jackpot from "./Jackpot";
import Loader from "./Loader";
import Profile from "./Profile";
import { calculateTotalWinnings } from "./Cardgrid";

const TabComponent = ({
  cardData,
  contract,
  account,
  token,
  approved,
  setApproved,
  balance,
  connectWallet,
  jackpotBets,
  fullFeatures = false,
  minstake,
  maxstake,
  myMatches,
}) => {
  const totalWinnings = calculateTotalWinnings({ matches: myMatches });
  const [openTab, setOpenTab] = useState(1);

  return (
    <div className='p-8 py-0'>
      <div className='w-[83vw] mx-auto'>
        <div className='mb-4 flex bg-darkgreen font-primary text-2xl text-stroke  rounded-xl shadow-md w-[100%]'>
          <button
            onClick={() => setOpenTab(1)}
            className={`py-1 w-[100%] rounded-xl focus:outline-none focus:shadow-outline-blue transition-all duration-300 ${
              openTab === 1 ? "bg-green text-white" : ""
            }`}
          >
            Games
          </button>
          <button
            onClick={() => setOpenTab(2)}
            className={` py-3 w-[100%]  rounded-xl focus:outline-none focus:shadow-outline-blue transition-all duration-300 ${
              openTab === 2 ? "bg-green text-white" : ""
            }`}
          >
            Jackpot
          </button>
          <button
            onClick={() => setOpenTab(3)}
            className={` py-3 w-[100%]  rounded-xl focus:outline-none focus:shadow-outline-blue transition-all duration-300 ${
              openTab === 3 ? "bg-green text-white" : ""
            }`}
          >
            My BetIs{" "}
          </button>
        </div>
        <div className='flex justify-center items-center w-[83vw] mx-auto'>
          <div className='bg-[#0B1428] opacity-75 rounded-xl p-4 flex flex-col-reverse sm:flex-row justify-between items-center w-full'>
            <div className='flex flex-col sm:flex-row justify-center items-center mx-2 w-[100%] sm:w-auto sm:space-x-12'>
              <div className=' flex flex-row-reverse sm:flex-col justify-between sm:justify-center items-center sm:items-start w-[100%] sm:w-auto'>
                <p className='text-xl'>FENS {totalWinnings.toFixed()}</p>
                <p className='text-gray font-secondary font-semibold '>
                  {" "}
                  Total Winnings{" "}
                </p>
              </div>
              <div className=' flex flex-row-reverse sm:flex-col justify-between sm:justify-center items-center sm:items-start w-[100%] sm:w-auto'>
                <p className='text-xl'>FENS {Number(balance).toFixed()}</p>
                <p className='text-gray font-secondary font-semibold '>
                  Belence{" "}
                </p>
              </div>
            </div>
            <div className='flex justify-center items-center mx-2 w-[100%] sm:w-auto'>
              <div className=' flex flex-row-reverse sm:flex-col justify-between sm:justify-center items-center sm:items-start w-[100%] sm:w-auto'>
                <p className='text-xl'>
                  {account ? (
                    <span className='sm:pr-3'>
                      {account?.slice(0, 4) +
                        "..." +
                        account?.slice(account.length - 4)}
                    </span>
                  ) : (
                    "-"
                  )}
                </p>
                <p className='text-gray font-secondary font-semibold '>
                  {" "}
                  Connected wallet{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        {openTab === 1 &&
          (!cardData ? (
            <Loader />
          ) : (
            <CardGrid
              balance={balance}
              cards={cardData}
              contract={contract}
              account={account}
              token={token}
              approved={approved}
              setApproved={setApproved}
              fullFeatures={fullFeatures}
              connectWallet={connectWallet}
              minstake={minstake}
              maxstake={maxstake}
            />
          ))}
        {openTab === 2 && (
          <Jackpot
            bets={jackpotBets}
            balance={balance}
            token={token}
            account={account}
            approved={approved}
            setApproved={setApproved}
            connectWallet={connectWallet}
            contract={contract}
            minstake={minstake}
            maxstake={maxstake}
          />
        )}
        {openTab === 3 && (
          <Profile
            myMatches={myMatches}
            account={account}
            contract={contract}
            token={token}
            balance={balance}
            approved={approved}
            setApproved={setApproved}
            minstake={minstake}
            maxstake={maxstake}
          />
        )}
      </div>
    </div>
  );
};

export default TabComponent;
