import React, { useState } from "react";
import Web3 from "web3";
import {
  BASE_SCAN_EVENT,
  CHANNEL_ID,
  CONTRACT_ADDRESS,
  COUNTRY_ABBVR,
  COUNTRY_FLAGS,
  X_API_KEY,
  messages,
} from "../constants";
import { useNavigate } from "react-router-dom";

// const CONTRACT_ADDRESS = "0xf97103539f806fb381d87520fb57C1664AafEEf8";
// const CONTRACT_ADDRESS = "0xc7Cf7b1c6DeC43dF9cB6CEc77f7d6932Ec00c2E3";
// const CONTRACT_ADDRESS = "0x5C1eFa1C004fEFe20368406A92552A8BCC327FFb";

const TEAM_VAL = {
  teamA: 0,
  teamB: 1,
  draw: 2,
};

export function simplifyFraction(numerator, denominator) {
  if (denominator === 0) {
    return 1;
  }

  function gcd(a, b) {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  }

  const greatestCommonDivisor = gcd(numerator, denominator);

  const simplifiedNumerator = numerator / greatestCommonDivisor;
  const simplifiedDenominator = denominator / greatestCommonDivisor;

  const result = simplifiedNumerator / simplifiedDenominator;
  return result % 1 === 0 ? result.toString() : result.toFixed(2);
}

const Card = ({
  contract,
  account,
  token,
  approved,
  setApproved,
  balance,
  teamA,
  teamB,
  startTime,
  fullFeatures,
  description,
  totalStakeTeamA,
  totalStakeTeamB,
  totalStakeDraw,
  outcomeDeclared,
  draw,
  teamAWon,
  indexvalue: matchId,
  num_stakers_A,
  num_stakers_B,
  num_stakers_Draw,
  my_staked_amount_A,
  my_staked_amount_B,
  my_staked_amount_Draw,
  connectWallet,
  winningOption: outcome,
  isProfile,
  minstake,
  maxstake,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedButtonData, setClickedButtonData] = useState({});
  const [betAmount, setBetAmount] = useState("");
  const [inputError, setInputError] = useState("");

  const navigate = useNavigate();

  // useEffect(() => {
  //   const isApproved = localStorage.getItem("walletApproved") === "true";
  //   if (isApproved) {
  //     setApproved(true);
  //   }
  // }, [setApproved]);

  const startTimeMillis = Number(startTime) * 1000;
  const fifteenMinutesLater = startTimeMillis + 15 * 60 * 1000;
  const isButtonDisabled =
    Date.now() > fifteenMinutesLater || outcomeDeclared !== false;

  const handleClick = (fraction, team, team_val, num_stakers) => {
    setClickedButtonData({
      fraction,
      team,
      team_val,
      match: `${teamA} vs ${teamB}`,
      pool:
        Number(totalStakeTeamA) +
        Number(totalStakeTeamB) +
        Number(totalStakeDraw),
      num_stakers,
    });
    setIsModalOpen(true);
    setBetAmount("");
    setInputError("");
  };

  const handleBetAmountChange = (e) => {
    setBetAmount(e.target.value);
  };

  const handlePlaceBet = async () => {
    const value = Number(betAmount);
    // if (account && value >= minstake && value <= maxstake && value <= balance) {
    if (account && value >= minstake && value <= 1000000 && value <= balance) {
      // hardcoding one million as maxStake till we fix the smartcontract
      // Place bet logic here
      try {
        // const betAmountInTokens = Web3.utils.toWei(value, "ether"); // Converts the amount to 18 decimals
        await contract.methods
          .placeStake(matchId, clickedButtonData.team_val, value)
          .send({ from: account });

        const payload = {
          channel_id: CHANNEL_ID,
          account: account,
          match_id: matchId,
          team_id: clickedButtonData.team_val,
          team_name: clickedButtonData.team,
          amount: value,
          match: `${teamA} VS ${teamB}`,
          message: "[BET PLACED SUCCESSFULLY]",
        };

        // Send an event after succefully placing the bet
        try {
          const initialResponse = await fetch(BASE_SCAN_EVENT, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              "x-api-key": X_API_KEY,
            },
            body: JSON.stringify(payload),
          });

          // Check for fetch errors
          if (!initialResponse.ok) {
            throw new Error(
              `Error sending initial event: ${initialResponse.statusText}`
            );
          } else {
            console.log({ res: initialResponse });
          }
        } catch (fetchError) {
          console.error("Error sending event to eventAPI:", fetchError);
        }

        alert(`Bet placed: ${value} on ${clickedButtonData.team}`);
        navigate(0);
      } catch (error) {
        console.error("Error placing bet:", error);
        alert("Error placing bet!");
      }
      setIsModalOpen(false);
    } else {
      // const message = "Please enter a valid amount between " +minstake + " and " + maxstake;
      const message =
        "Please enter a valid amount between " + minstake + " and " + 1000000;
      setInputError(message);
    }
  };

  const handleapprove = async () => {
    if (account && token) {
      try {
        const maxAmountInTokens = Web3.utils.toWei(10 ** 10, "ether");
        await token.methods
          .approve(CONTRACT_ADDRESS, maxAmountInTokens)
          .send({ from: account });
        setApproved(true);
        localStorage.setItem("walletApproved", "true");
        alert("Staking approved successfully!");
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error approving stake:", error);
        alert("Error approving stake!");
      }
    }
  };

  const potentialOutcome = betAmount
    ? clickedButtonData.fraction * betAmount
    : 0;

  const isWinner =
    outcomeDeclared !== false &&
    ((Number(outcome) === TEAM_VAL.teamA && my_staked_amount_A) ||
      (Number(outcome) === TEAM_VAL.teamB && my_staked_amount_B) ||
      (Number(outcome) === TEAM_VAL.draw && my_staked_amount_Draw));

  const winningAmount = () => {
    let my_staked_amount;
    let totalStakeOnTeam;

    switch (Number(outcome)) {
      case 0:
        my_staked_amount = my_staked_amount_A;
        totalStakeOnTeam = totalStakeTeamA;
        break;
      case 1:
        my_staked_amount = my_staked_amount_B;
        totalStakeOnTeam = totalStakeTeamB;
        break;
      case 2:
        my_staked_amount = my_staked_amount_Draw;
        totalStakeOnTeam = totalStakeDraw;
        break;
      default:
        break;
    }

    return (
      ((Number(totalStakeTeamA) +
        Number(totalStakeTeamB) +
        Number(totalStakeDraw)) /
        Number(totalStakeOnTeam)) *
      Number(my_staked_amount) *
      0.9
    ).toFixed(); // 10% is burned so only 90% available for cash-out
  };

  console.log(teamA, teamB, "teams");
  return (
    <>
      <li className=''>
        <div className=' m-4 p-4 bg-[#061D4BBF] text-white rounded-lg bg-opacity-75 space-y-4  w-[83vw] mx-auto'>
          <div className='grid grid-rows-2 grid-cols-2 sm:grid-rows-2 sm:grid-cols-custom lg:grid-cols-custom justify-between items-center font-secondary'>
            <p className='row-start-2 col-start-1 col-end-1 sm:row-start-1  text-base  sm:text-lg'>
              Starts At {formatTime(Number(startTime))}
            </p>
            <div className='row-start-1 col-start-1 col-end-3 lg:col-start-2  sm: w-full sm:w-full'>
              {isWinner && winningAmount() !== 0 ? (
                <p className='bg-[#EDB92E] text-stroke w-[100%] rounded-lg text-center font-bold  text-base  sm:text-2xl font-darumadrop'>
                  My Winnings :{" "}
                  <span className='font-primary'>{`FENS ${winningAmount()}`}</span>
                </p>
              ) : isWinner && winningAmount() === 0 ? (
                <p className='bg-red-400 text-stroke w-[100%] rounded-lg text-center font-bold text-base  sm:text-xl font-darumadrop'>
                  You Loose
                  {/* <span className='font-primary'>{`$${winningAmount()}`}</span> */}
                </p>
              ) : isProfile ? (
                <p className='bg-red-400 text-stroke w-[100%] rounded-lg text-center font-bold text-base  sm:text-xl font-darumadrop'>
                  Yet to Fenish
                  {/* <span className='font-primary'>{`$${winningAmount()}`}</span> */}
                </p>
              ) : (
                <p className='w-[100%] text-center text-base  sm:text-xl'>
                  {messages[Math.floor(Math.random() * (15 - 1) + 1)]}
                </p>
              )}
            </div>

            <div className='font-secondary row-start-2 col-start-2 sm:row-start-1 sm:col-start-3 flex flex-col sm:flex-row text-right sm:justify-end text-base sm:text-l'>
              <p className=' lg:mr-2'>Reward Pool &nbsp; :</p>
              <span className='font-primary'>
                {`FENS`} &nbsp;
                {Number(totalStakeTeamA) +
                  Number(totalStakeTeamB) +
                  Number(totalStakeDraw)}
              </span>
            </div>
          </div>
          <div className='grid grid-cols-2 grid-rows-3 sm:grid-rows-1 sm:grid-cols-custom lg:grid-cols-custom2 gap-4 justify-between items-center'>
            <div className='flex flex-col sm:flex-row row-start-1 col-start-1 lg:col-start-1 lg:col-end-2 justify-start items-start sm:items-center'>
              <img
                src={COUNTRY_FLAGS[teamA?.toLowerCase()]}
                alt='flag'
                className='w-20 h-auto'
              />
              <p className='text-xl ml-2 '>{teamA?.toUpperCase()}</p>
            </div>

            <div className='flex flex-col sm:flex-row gap-4 justify-center items-center row-start-2 row-end-4 col-start-1 col-end-3 sm:col-start-2 sm:row-start-1 sm:row-end-2 lg:col-start-2 lg:col-end-3'>
              <button
                className={`bg-[#00BDE5] px-2 flex justify-around items-center w-[100%] sm:w-[33%] rounded-md ${
                  isButtonDisabled ? "cursor-not-allowed opacity-60" : ""
                }  ${
                  outcomeDeclared && Number(outcome) === TEAM_VAL.teamA
                    ? "text-[#EDB92E]"
                    : ""
                }`}
                disabled={isButtonDisabled}
                onClick={() =>
                  handleClick(
                    simplifyFraction(
                      Number(totalStakeTeamA) +
                        Number(totalStakeTeamB) +
                        Number(totalStakeDraw),
                      Number(totalStakeTeamA)
                    ),
                    teamA,
                    TEAM_VAL.teamA,
                    num_stakers_A
                  )
                }
              >
                {outcomeDeclared && Number(outcome) === TEAM_VAL.teamA ? (
                  <img
                    src='/golden-trophy.png'
                    alt='winner'
                    className='w-8 h-8'
                  />
                ) : null}

                <span className='text-black  text-xl font-semibold w-full'>
                  {simplifyFraction(
                    Number(totalStakeTeamA) +
                      Number(totalStakeTeamB) +
                      Number(totalStakeDraw),
                    Number(totalStakeTeamA)
                  )}{" "}
                  X
                </span>

                <div
                  className={` px-2 py-1 text-base w-full font-secondary m-1 font-semibold text-black rounded-md ${
                    outcomeDeclared && Number(outcome) === TEAM_VAL.teamA
                      ? " bg-[#2E5BED]"
                      : " bg-[#93E9FF]"
                  }`}
                >
                  <p
                    className={`z-10   ${
                      outcomeDeclared && Number(outcome) === TEAM_VAL.teamA
                        ? "text-[#EDB92E] "
                        : ""
                    }`}
                  >
                    {" "}
                    {num_stakers_A} BETS on{" "}
                    {COUNTRY_ABBVR[teamA?.toLowerCase()]}{" "}
                  </p>
                </div>
              </button>
              <button
                className={`bg-[#00BDE5] px-2 flex justify-around items-center w-[100%] sm:w-[33%] rounded-md ${
                  isButtonDisabled ? "cursor-not-allowed opacity-60" : ""
                }  ${
                  outcomeDeclared && Number(outcome) === TEAM_VAL.draw
                    ? "text-[#EDB92E]"
                    : ""
                }`}
                disabled={isButtonDisabled}
                onClick={() =>
                  handleClick(
                    simplifyFraction(
                      Number(totalStakeTeamA) +
                        Number(totalStakeTeamB) +
                        Number(totalStakeDraw),
                      Number(totalStakeDraw)
                    ),
                    "draw",
                    TEAM_VAL.draw,
                    num_stakers_Draw
                  )
                }
              >
                {outcomeDeclared && Number(outcome) === TEAM_VAL.draw ? (
                  <img src='/golden-trophy.png' alt='winner' />
                ) : null}
                <span className='text-black font-semibold text-xl '>
                  {simplifyFraction(
                    Number(totalStakeTeamA) +
                      Number(totalStakeTeamB) +
                      Number(totalStakeDraw),
                    Number(totalStakeDraw)
                  )}{" "}
                  X
                </span>
                <div
                  className={`px-2 py-1 font-secondary m-1 text-base font-semibold text-black rounded-md ${
                    Number(outcome) === TEAM_VAL.draw
                      ? "bg-[#2E5BED]"
                      : "bg-[#93E9FF]"
                  }`}
                >
                  <p
                    className={`z-10 w-full whitespace-nowrap ${
                      outcomeDeclared && Number(outcome) === TEAM_VAL.draw
                        ? "text-[#EDB92E]"
                        : ""
                    }`}
                  >
                    {num_stakers_Draw} BETS on draw
                  </p>
                </div>
              </button>

              <button
                className={`bg-[#00BDE5] px-2 flex justify-around items-center w-[100%] sm:w-[33%] rounded-md ${
                  isButtonDisabled ? "cursor-not-allowed opacity-60" : ""
                } ${
                  outcomeDeclared && Number(outcome) === TEAM_VAL.teamB
                    ? "text-[#EDB92E]"
                    : ""
                }`}
                disabled={isButtonDisabled}
                onClick={() =>
                  handleClick(
                    simplifyFraction(
                      Number(totalStakeTeamA) +
                        Number(totalStakeTeamB) +
                        Number(totalStakeDraw),
                      Number(totalStakeTeamB)
                    ),
                    teamB,
                    TEAM_VAL.teamB,
                    num_stakers_B
                  )
                }
              >
                {outcomeDeclared && Number(outcome) === TEAM_VAL.teamB ? (
                  <img src='/golden-trophy.png' alt='winner' />
                ) : null}
                <span className='text-black font-semibold text-xl '>
                  {simplifyFraction(
                    Number(totalStakeTeamA) +
                      Number(totalStakeTeamB) +
                      Number(totalStakeDraw),
                    Number(totalStakeTeamB)
                  )}{" "}
                  X
                </span>
                <div
                  className={` px-2 py-1  font-secondary  text-base m-1 font-semibold text-black rounded-md ${
                    outcomeDeclared && Number(outcome) === TEAM_VAL.teamB
                      ? "bg-[#2E5BED]"
                      : " bg-[#93E9FF]"
                  }`}
                >
                  {" "}
                  <p
                    className={`z-10  ${
                      outcomeDeclared && Number(outcome) === TEAM_VAL.teamB
                        ? "text-[#EDB92E]"
                        : ""
                    }`}
                  >
                    {" "}
                    {num_stakers_B} BETS on{" "}
                    {COUNTRY_ABBVR[teamB?.toLowerCase()]}{" "}
                  </p>
                </div>
              </button>
            </div>
            <div className='flex flex-col-reverse sm:flex-row justify-end items-end sm:items-center row-start-1 col-start-2 sm:col-start-3 lg:col-start-3 lg:col-end-4'>
              <p className='text-xl mr-2'>{teamB?.toUpperCase()}</p>
              <img
                src={COUNTRY_FLAGS[teamB?.toLowerCase()]}
                alt='flag'
                className='w-20 h-auto'
              />
            </div>
          </div>

          {fullFeatures === true ? (
            <div className='flex flex-col gap-1 justify-between items-center w-full sm:w-[65%] mx-auto'>
              <div className='flex justify-between items-center w-full mx-auto'>
                {isProfile && Number(my_staked_amount_A) > 0 ? (
                  <div className='text-center flex-1'>
                    <p className='font-secondary flex flex-col sm:block'>
                      <span>Bet on {teamA} </span>
                      <span className='font-primary'>
                        {my_staked_amount_A
                          ? `FENS ${Number(my_staked_amount_A)}`
                          : "-"}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className='text-center flex-1'>
                    <p className='font-secondary'> </p>
                  </div>
                )}

                {isProfile && Number(my_staked_amount_Draw) > 0 ? (
                  <div className='text-center flex-1'>
                    <p className='font-secondary'>
                      Bet on Draw{" "}
                      <span className='font-primary'>
                        {my_staked_amount_Draw
                          ? `FENS ${Number(my_staked_amount_Draw)}`
                          : "-"}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className='text-center flex-1'>
                    <p className='font-secondary'> </p>
                  </div>
                )}

                {isProfile && Number(my_staked_amount_B) > 0 ? (
                  <div className='text-center flex-1'>
                    <p className='font-secondary'>
                      Bet on {teamB}{" "}
                      <span className='font-primary'>
                        {my_staked_amount_B
                          ? `FENS ${Number(my_staked_amount_B)}`
                          : "-"}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className='text-center flex-1'>
                    <p className='font-secondary'> </p>
                  </div>
                )}
              </div>

              {/* {isWinner ? (
                <div className='flex justify-around items-center w-[100%] mx-auto'>                
                <p className='font-secondary'>
                  Winns <span className='font-primary'>{(Number(outcome) === TEAM_VAL.teamA && my_staked_amount_A) ? `$${
                    winningAmount()}` : "$0"}
                  </span>
                </p>
                <p className='font-secondary'>
                  Winns <span className='font-primary'>{(Number(outcome) === TEAM_VAL.draw && my_staked_amount_Draw) ? `$${
                  winningAmount()}` : "$0"}</span>
                </p>
                <p className='font-secondary'>
                  Winns <span className='font-primary'>{(Number(outcome) === TEAM_VAL.teamB && my_staked_amount_B) ? `$${
                  winningAmount()}` : "$0"}</span>
                </p>
                </div>
              ): null} */}
            </div>
          ) : null}
        </div>
      </li>

      {isModalOpen && (
        <div className='fixed inset-0 flex items-center justify-center p-16'>
          <div className='bg-[#122243] p-4 rounded-lg shadow-lg relative lg:min-w-[450px]'>
            <div className='flex justify-between border-[#0B1428] border-b-2 pb-2'>
              <div className='flex gap-2 space-x-4'>
                <img
                  src={COUNTRY_FLAGS[clickedButtonData?.team.toLowerCase()]}
                  alt='country-flag'
                />
                <div className='flex flex-col justify-center'>
                  <p className='mt-0 text-xl text-white uppercase'>
                    {clickedButtonData.team}
                  </p>
                  <p className='mt-0 text-base text-[#8991A2] capitalize font-secondary'>
                    {clickedButtonData.match}
                  </p>
                </div>
              </div>
              <button
                onClick={() => setIsModalOpen(false)}
                className='text-3xl text-gray font-secondary'
              >
                X
              </button>
            </div>
            <div className='flex justify-between items-center py-2 '>
              <span className='font-secondary text-gray text-lg'>
                Reward Pool: &nbsp;
              </span>
              <span className='font-primary'>
                FENS &nbsp;
                {Number(totalStakeTeamA) +
                  Number(totalStakeTeamB) +
                  Number(totalStakeDraw)}
              </span>
            </div>
            <div className='flex justify-between'>
              <p className='flex space-x-2'>
                <span>{clickedButtonData.fraction}x</span>
                <span className='font-secondary text-gray text-lg'>
                  Winnings
                </span>{" "}
              </p>
              <p className='flex space-x-2'>
                <span className='font-secondary text-gray text-lg'>BET(s)</span>{" "}
                <span> {clickedButtonData.num_stakers}</span>
              </p>
            </div>
            <p className='mt-2 text-sm text-gray-500'></p>
            <input
              // type='number'
              value={betAmount}
              onChange={handleBetAmountChange}
              placeholder='enter amount'
              in
              className='mt-2 w-full text-lg  font-semibold font-secondary border border-gray-300 rounded-lg p-2 text-[#00BDE5] bg-[#0B1428] placeholder-green outline-none'
            />

            <div className='flex justify-end  py-2 space-x-3'>
              <span className='font-secondary text-gray text-xl mr-1'>
                Balance
              </span>{" "}
              <span>FENS {balance ? balance : "0"}</span>
            </div>
            {inputError && (
              <p className='mt-2 text-sm text-red-500'>{inputError}</p>
            )}
            <div className='mt-4 flex justify-between space-x-4'>
              <p className='mt-2 flex flex-col'>
                <span className='font-secondary text-gray lg:text-lg'>
                  {" "}
                  Potential Outcome:
                </span>
                <span className=''> FENS {potentialOutcome.toFixed()}</span>
              </p>
              {account ? (
                !approved ? (
                  <button
                    className='bg-[#00BDE5] font-secondary min-w-44 text-xl font-bold px-4 py-2 text-stroke text-white rounded-lg'
                    onClick={handleapprove}
                  >
                    Approve
                  </button>
                ) : (
                  <button
                    className='bg-[#00BDE5] font-secondary min-w-44 text-xl font-bold px-4 py-2 text-stroke text-white rounded-lg'
                    onClick={handlePlaceBet}
                  >
                    Bet !!!!
                  </button>
                )
              ) : (
                <button
                  className='bg-[#00BDE5] font-secondary text-xs px-1   lg:min-w-44 lg:text-lg  lg:px-4  lg:py-2 text-stroke text-white rounded-lg'
                  onClick={connectWallet}
                >
                  {!account ? "CONNECT WALLET" : " DISCONNECT "}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const calculateTotalWinnings = ({ matches }) => {
  // console.log("matches recieved", matches);
  return matches.reduce((acc, match) => {
    let my_staked_amount;
    let totalStakeOnTeam;

    console.log("log the match here", match);

    switch (Number(match.winningOption)) {
      case TEAM_VAL.teamA:
        my_staked_amount = match.my_staked_amount_A;
        totalStakeOnTeam = match.totalStakeTeamA;
        break;
      case TEAM_VAL.teamB:
        my_staked_amount = match.my_staked_amount_B;
        totalStakeOnTeam = match.totalStakeTeamB;
        break;
      case TEAM_VAL.draw:
        my_staked_amount = match.my_staked_amount_Draw;
        totalStakeOnTeam = match.totalStakeDraw;
        break;
      default:
        return acc;
    }

    console.log({ my_staked_amount, totalStakeOnTeam });

    if (match.outcomeDeclared !== false) {
      const fraction = Number(totalStakeOnTeam)
        ? (Number(match.totalStakeTeamA) +
            Number(match.totalStakeTeamB) +
            Number(match.totalStakeDraw)) /
          Number(totalStakeOnTeam)
        : 1;
      const winningAmount = fraction * Number(my_staked_amount) * 0.9; // 10% is burned so only 90% available for cash-out

      console.log({ winningAmount });
      return acc + winningAmount;
    }

    console.log({ result: acc });

    return acc;
  }, 0);
};

export const formatDate = (timestamp, noWeekDay) => {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const month = date.toLocaleString("en-GB", { month: "long" });
  const year = date.getFullYear();
  const weekday = date.toLocaleString("en-GB", { weekday: "long" });
  return noWeekDay
    ? `${day} ${month} ${year}`
    : `${day} ${month} ${year}, ${weekday}`;
};

export const formatTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

const groupMatchesByDate = (cards) => {
  const currentDate = new Date().toDateString();

  const grouped = cards.reduce((acc, card) => {
    const date = formatDate(Number(card?.startTime));
    if (!acc[date]) {
      acc[date] = {
        ongoing: [],
        completed: [],
      };
    }

    // Separate matches into ongoing and completed based on outcomeDeclared
    if (card.outcomeDeclared) {
      acc[date].completed.push(card);
    } else {
      acc[date].ongoing.push(card);
    }

    return acc;
  }, {});

  const sortedGrouped = {};
  Object.keys(grouped).forEach((date) => {
    if (date === currentDate) {
      sortedGrouped[date] = grouped[date];
    } else if (grouped[date].ongoing.length > 0) {
      sortedGrouped[date] = grouped[date];
    }
  });

  const completed = {};
  Object.keys(grouped).forEach((date) => {
    if (grouped[date].completed.length > 0) {
      completed[date] = grouped[date].completed;
    }
  });

  return { ongoing: sortedGrouped, completed };
};

const CardGrid = ({
  cards,
  contract,
  account,
  token,
  approved,
  setApproved,
  balance,
  fullFeatures,
  connectWallet,
  isProfile,
  minstake,
  maxstake,
}) => {
  // const groupedMatches = groupMatchesByDate(cards);
  const { ongoing, completed } = groupMatchesByDate(cards);

  return (
    <div>
      <ul className='mt-8'>
        {cards &&
          ongoing &&
          Object.keys(ongoing).map((date) => (
            <React.Fragment key={date}>
              <h2 className='text-white text-left text-3xl font-primary text-stroke w-[83vw] mx-auto'>
                {date}
              </h2>
              {ongoing[date]?.ongoing?.map((card, index) => (
                <Card
                  key={index}
                  {...card}
                  contract={contract}
                  account={account}
                  token={token}
                  approved={approved}
                  setApproved={setApproved}
                  balance={balance}
                  fullFeatures={fullFeatures}
                  connectWallet={connectWallet}
                  isProfile={isProfile}
                  outcomeDeclared={card.outcomeDeclared}
                  minstake={minstake}
                  maxstake={maxstake}
                />
              ))}
            </React.Fragment>
          ))}

        {Object.keys(completed).length > 0 && (
          <>
            <h2 className='text-white text-center text-5xl font-primary text-thick-stroke w-[83vw] mx-auto'>
              Completed
            </h2>
            {Object.keys(completed).map((date) => (
              <React.Fragment key={date}>
                <h3 className='text-white text-left text-3xl font-primary text-stroke w-[83vw] mx-auto'>
                  {date}
                </h3>
                {completed[date].map((card, index) => (
                  <Card
                    key={index}
                    {...card}
                    contract={contract}
                    account={account}
                    token={token}
                    approved={approved}
                    setApproved={setApproved}
                    balance={balance}
                    fullFeatures={fullFeatures}
                    connectWallet={connectWallet}
                    isProfile={isProfile}
                    outcomeDeclared={card.outcomeDeclared}
                    minstake={minstake}
                    maxstake={maxstake}
                  />
                ))}
              </React.Fragment>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export default CardGrid;
