import React from "react";

const MatchCard = ({
  teamA, 
  teamB,
  startTime,
  description,
  totalStakeTeamA,
  totalStakeTeamB,
  outcomeDeclared,
  draw,
  teamAWon,
  indexvalue: matchId
}) => {
  return (
    <div className=' m-4 p-4 bg-[#061D4BBF] text-white rounded-lg bg-opacity-75 space-y-4 '>
      <div className='flex justify-between items-center font-secondary'>
        <p>Starts At 12:30pm</p>
        <p>Bet for the winning team</p>
        <p>Reward Pool </p>
      </div>
      <div className='flex justify-between items-center space-x-2 '>
        <div className='flex justify-center items-center space-x-4'>
          <img
            src='/country/Germany.png'
            alt='Germany flag'
            className='rounded-full w-16 h-16'
          />
          <p>Germany</p>
        </div>

        <div className='flex justify-center items-center w-[60%]  space-x-4'>
          <div className='bg-[#00BDE5] px-2 flex justify-around items-center w-[33%] rounded-md'>
            <p>1/3</p>
            <div className='bg-[#12224340] p-1 text-xs font-secondary m-1 text-outline rounded-md'>
              <p className='z-10'> 200 BETS on Germany </p>
            </div>
          </div>
          <div className='bg-[#00BDE5] px-2 flex justify-around items-center w-[33%] rounded-md'>
            <p>1/3</p>
            <div className='bg-[#12224340] p-1 text-xs font-secondary m-1 text-outline rounded-md'>
              <p className='z-10'> 200 BETS on Germany </p>
            </div>
          </div>
          <div className='bg-[#00BDE5] px-2 flex justify-around items-center w-[33%] rounded-md'>
            <p>1/3</p>
            <div className='bg-[#12224340] p-1 text-xs font-secondary m-1 text-outline rounded-md'>
              <p className='z-10'> 200 BETS on Germany </p>
            </div>
          </div>
        </div>
        <div className='flex justify-center items-center space-x-4'>
          <p>Austria</p>
          <img
            src='/country/Austria.png'
            alt=' flag'
            className='rounded-full w-16 h-16'
          />
        </div>
      </div>
      <div className='flex justify-center items-center space-x-16'>
        <div className='flex flex-col justify-center items-center space-y-4'>
          <p className='font-secondary'>
            Bet on Germany <span className='font-primary'>$1000K</span>
          </p>
          <p className='font-secondary'>
            Winns <span className='font-primary'>$1000K</span>
          </p>
        </div>
        <div className='flex flex-col justify-center items-center space-y-4'>
          <p className='font-secondary'>
            Bet on Germany <span className='font-primary'>$1000K</span>
          </p>
          <p className='font-secondary'>
            Winns <span className='font-primary'>$1000K</span>
          </p>
        </div>
        <div className='flex flex-col justify-center items-center space-y-4'>
          <p className='font-secondary'>
            Bet on Germany <span className='font-primary'>$1000K</span>
          </p>
          <p className='font-secondary'>
            Winns <span className='font-primary'>$1000K</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MatchCard;
