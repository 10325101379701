import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const POSSIBLE_NAVIGATIONS = {
  COPA: "copa",
  EURO: "euro",
  PROFILE: "profile",
};

const Header = ({
  balance,
  account,
  connectWallet,
  handleDisconnect,
  selectedNav,
  setSelectedNav,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = () => {
    if (location.pathname === "/profile") {
      setIsScrolled(true);
    } else {
      if (window.scrollY > window.innerHeight - 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const section = query.get("section");
    if (section) {
      setSelectedNav(section);
    }
  }, [location.search]);

  const handleNavClick = (navItem) => {
    setSelectedNav(navItem);
    if (navItem === "euro" || navItem === "copa") {
      navigate("/");
      setTimeout(() => {
        document
          .getElementById("matches")
          .scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else {
      navigate("/");
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header
      className={`fixed top-0 w-full z-50 transition-all duration-300 bg-[#0B1428] sm:${
        isScrolled
          ? "sm:bg-[#0B1428] lg:bg-[#0B1428]"
          : "sm:bg-transparent lg:bg-transparent"
      } px-4 md:px-16 py-6`}
    >
      <div className='flex justify-between items-center '>
        <a href='/' className='flex items-center'>
          <img
            src='/applogoname.png'
            alt='logo'
            className='h-auto w-[116px] sm:w-[200px] lg:w-[200px]'
          />
        </a>
        <div className='md:hidden'>
          <button onClick={toggleMenu} className='text-white text-2xl'>
            &#9776;
          </button>
        </div>
        <nav
          className={`hidden md:flex sm:mb-0 sm:mr-[240px] space-x-8 lg:space-x-48 ml-auto lg:ml-0 `}
        >
          <a
            href='#matches'
            className={`text-2xl ${
              isScrolled
                ? "text-stroke-reverse text-[#0B1428]"
                : "text-stroke text-primaryAction"
            } ${
              selectedNav === POSSIBLE_NAVIGATIONS.EURO
                ? "text-primaryAction"
                : ""
            }`}
            onClick={() => handleNavClick(POSSIBLE_NAVIGATIONS.EURO)}
          >
            EURO CUP
          </a>
          <a
            href='#matches'
            className={`text-2xl ${
              isScrolled
                ? "text-stroke-reverse text-[#0B1428]"
                : "text-stroke text-primaryAction"
            } ${
              selectedNav === POSSIBLE_NAVIGATIONS.COPA
                ? "text-primaryAction"
                : ""
            }`}
            onClick={() => handleNavClick(POSSIBLE_NAVIGATIONS.COPA)}
          >
            COPA CUP
          </a>
        </nav>
        <div className='hidden md:flex justify-center items-center space-x-12'>
          {/* <Link
            to='/profile'
            className={`text-2xl ${
              isScrolled
                ? "text-stroke-reverse text-[#0B1428]"
                : "text-stroke text-primaryAction"
            } ${
              selectedNav === POSSIBLE_NAVIGATIONS.PROFILE
                ? "text-primaryAction"
                : ""
            }`}
            onClick={() => handleNavClick(POSSIBLE_NAVIGATIONS.PROFILE)}
          >
            PROFYLE
          </Link> */}
          <button
            className='bg-[#00BDE5] min-w-44 text-lg px-4 py-2 text-stroke text-white rounded-lg'
            onClick={() => {
              account ? handleDisconnect() : connectWallet();
            }}
          >
            {!account ? "CONNECT WALLET" : "DISCONNECT"}
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className='md:hidden mt-2 h-[100vh]'>
          <nav className='flex flex-col mt-[24vw] gap-7 items-center h-[100%] w-[100%]'>
            <a href='/' className='flex items-center'>
              <img
                src='/mobile-logo.png'
                alt='logo'
                className='h-[146px] w-[146px] sm:w-[200px] lg:w-[200px]'
              />
            </a>
            <a
              href='#matches'
              className={`text-2xl text-stroke-reverse text-[#0B1428] ${
                selectedNav === POSSIBLE_NAVIGATIONS.EURO
                  ? "text-primaryAction"
                  : ""
              }`}
              onClick={() => {
                handleNavClick(POSSIBLE_NAVIGATIONS.EURO);
                toggleMenu();
              }}
            >
              EURO CUP
            </a>
            <a
              href='#matches'
              className={`text-2xl text-stroke-reverse text-[#0B1428] ${
                selectedNav === POSSIBLE_NAVIGATIONS.COPA
                  ? "text-primaryAction"
                  : ""
              }`}
              onClick={() => {
                handleNavClick(POSSIBLE_NAVIGATIONS.COPA);
                toggleMenu();
              }}
            >
              COPA CUP
            </a>
            {/* <Link
              to='/profile'
              className={`text-2xl text-stroke-reverse text-[#0B1428] ${
                selectedNav === POSSIBLE_NAVIGATIONS.PROFILE
                  ? "text-primaryAction"
                  : ""
              }`}
              onClick={() => {
                handleNavClick(POSSIBLE_NAVIGATIONS.PROFILE);
                toggleMenu();
              }}
            >
              PROFYLE
            </Link> */}
            <button
              className='bg-[#00BDE5] min-w-44 text-lg px-4 py-2 text-stroke text-white rounded-lg'
              onClick={() => {
                account ? handleDisconnect() : connectWallet();
                toggleMenu();
              }}
            >
              {!account ? "CONNECT WALLET" : "DISCONNECT"}
            </button>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
