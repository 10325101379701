import React from 'react'
import BetCard from './Betcard';
import { formatDate, formatTime } from './Cardgrid';

const Jackpot = ({
  bets,
  balance,
  token,
  account,
  approved,
  setApproved,
  connectWallet,
  contract,
  minstake,
  maxstake,
}) => {
  console.log({bets});

  const totalJackpotAmount  =  bets && bets['bets']?.reduce((accumulator, currentEl) => {
                                console.log({currentEl})
                                return accumulator + Number(currentEl?.betAmount)
                              }, 0);
                              
    return (
        <div>
          <h1 className="text-3xl font-primary text-white text-center mb-8 mt-8">BET FOR TOURNAMENT WINNER</h1>
          <div className='flex justify-center items-center w-[83vw] mx-auto mb-4'>
            <div className='bg-[#0B1428] opacity-75 rounded-xl p-4 flex justify-between items-center w-full'>
              <div className='flex justify-center items-center space-x-12 mx-2'>
                <div className=' flex flex-col justify-center items-start '>
                  <p className='text-xl'>FENS {totalJackpotAmount}</p>
                  <p className='text-gray font-secondary font-semibold '>
                    {" "}
                    Reward Pool{" "}
                  </p>
                </div>
                {/* <div className=' flex flex-col justify-center items-start '>
                  <p className='text-xl'>FENS {Number(balance).toFixed(2)}</p>
                  <p className='text-gray font-secondary font-semibold '>
                    Belence{" "}
                  </p>
                </div> */}
              </div>
              <div className='flex justify-center items-center space-x-12 mx-2'>
                <div className=' flex flex-col justify-center items-end '>
                  <p className='text-xl'>
                    {bets['startTime'] ? formatDate(Number(bets['startTime']), true) + ', ' + formatTime(Number(bets['startTime'])): '-'}
                  </p>
                  <p className='text-gray font-secondary font-semibold '>
                    {" "}
                    Open till{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4">
            {bets && bets['bets']?.map((bet, index) => (
              <BetCard
                key={index}
                country={bet.country}
                totalBets={bet.totalBets}
                betAmount={bet.betAmount}
                country_val={bet.country_val}
                matchId={bet.matchId}
                balance={balance}
                token={token}
                account={account}
                approved={approved}
                setApproved={setApproved}
                connectWallet={connectWallet}
                contract={contract}
                minstake={minstake}
                maxstake={maxstake}
                totalJackpotAmount={totalJackpotAmount}
              />
            ))}
          </div>
        </div>
      );
}

export default Jackpot;