export const COUNTRY_FLAGS = {
  albania: "/country/albania.png",
  austria: "/country/austria.png",
  belgium: "/country/belgium.png",
  croatia: "/country/croatia.png",
  czech: "/country/czech.png",
  czechia: "/country/czech.png",
  denmark: "/country/denmark.png",
  england: "/country/england.png",
  france: "/country/france.png",
  georgia: "/country/georgia.png",
  germany: "/country/germany.png",
  hungary: "/country/hungary.png",
  italy: "/country/italy.png",
  netherlands: "/country/netherlands.png",
  poland: "/country/poland.png",
  portugal: "/country/portugal.png",
  romania: "/country/romania.png",
  scotland: "/country/scotland.png",
  serbia: "/country/serbia.png",
  slovakia: "/country/slovakia.png",
  slovenia: "/country/slovenia.png",
  spain: "/country/spain.png",
  switzerland: "/country/switzerland.png",
  turkey: "/country/turkey.png",
  turkiye:"/country/turkey.png",
  ukraine: "/country/ukraine.png",
  draw:"/Logo.png"
};

export const COUNTRY_ABBVR = {
  albania: "ALB",
  austria: "AUT",
  belgium: "BEL",
  croatia: "CRO",
  czech: "CZE",
  czechia:"CZE",
  denmark: "DEN",
  england: "ENG",
  france: "FRA",
  georgia: "GEO",
  germany: "GER",
  hungary: "HUN",
  italy: "ITA",
  netherlands: "NED",
  poland: "POL",
  portugal: "POR",
  romania: "ROU",
  scotland: "SCO",
  serbia: "SRB",
  slovakia: "SVK",
  slovenia: "SVN",
  spain: "ESP",
  switzerland: "SUI",
  turkey: "TUR",
  turkiye: "TUR",
  ukraine: "UKR",
};

export const iconLink = {
  dexscreener:
    "https://dexscreener.com/base/0x838B76C055C92494493B544647E8803a180e9C6E",
  buy:"https://app.uniswap.org/swap?outputCurrency=0x838B76C055C92494493B544647E8803a180e9C6E&chain=base",
  twitter: "https://x.com/Loyel_Fen",
  telegram: "https://t.me/LoyelFen",
  
};

export const CONTRACT_ADDRESS = "0x7cC53AC8E586A1a68c7760aB3b9FBc57f8Fbc14b";

export const TOKEN_ADDRESS = "0x838B76C055C92494493B544647E8803a180e9C6E";

export const messages = ["Bet on your team and let the FENS frenzy begin!",
  "FENS up! Put your money where your memes are!",
  "Hello FENS! It's time to back your favorite squad!",
  "Don't just watch, FENS it up and bet on your team!",
  "Turn your FENS into wins! Bet now!",
  "FENS to the moon! Place your bet and cheer loud!",
  "Betting is better with FENS! Support your heroes!",
  "Why just be a fan when you can be a FEN$? Bet now!",
  "FENS unite! Bet on your team's victory!",
  "Pump up your FENS! Bet on the best and forget the rest!",
  "Your FENS, your team, your win! Place your bet!",
  "Get those FENS rolling! Bet on your fav footballers!",
  "FENS on the field! Show your support with a bet!",
  "Bet for glory, bet for FENS, bet for fun!",
  "Make your FENS count! Place your bet on the champions!"
  ]

  
  export const BASE_SCAN_EVENT = "https://hlcanoo004.execute-api.eu-west-1.amazonaws.com/v1/base-scan";
  export const X_API_KEY = "tw7vLxYwyi1WDn2IJEhqT43D4X7DCOr93HXweR08";
  // export const CHANNEL_ID = "-1002150177927"; //testing group id
  export const CHANNEL_ID = "-1002221303141"; //prod group id