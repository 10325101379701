import React, { useEffect, useState } from "react";
import MatchCard from "./MatchCard";
import EuroCupComponent from "./EuroCupComponent";
import CopaCupComponent from "./CopaCupComponent";
import TabComponent from "./TabComponent";
import { TOKEN_ADDRESS } from "../constants";
import { useNavigate } from "react-router-dom";
import copy from "clipboard-copy";
import { iconLink } from "../constants";

// const bets = [
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "germany", totalBets: "11/12", betAmount: "1000K" },
//   { country: "italy", totalBets: "11/12", betAmount: "1000K" },
//   { country: "italy", totalBets: "11/12", betAmount: "1000K" },
//   { country: "italy", totalBets: "11/12", betAmount: "1000K" },
//   { country: "italy", totalBets: "11/12", betAmount: "1000K" },
// ];

function Home({
  contract,
  account,
  token,
  approved,
  setApproved,
  balance,
  selectedNav,
  matches,
  myMatches,
  connectWallet,
  minstake,
  maxstake,
  bets,
}) {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);

  const handleNavClick = () => {
    navigate("/");
    setTimeout(() => {
      document.getElementById("matches").scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleIconClick = (icon) => {
    window.open(iconLink[icon], "_blank");
  };
  const handleBuynowclick = () => {
    window.open(iconLink["buy"], "_blank");
  };
  const onCopyText = async (ctx) => {
    try {
      await copy(ctx);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      console.error("Error copying to clipboard", error);
    }
  };
  return (
    <div>
      <section
        className='relative w-screen h-screen overflow-hidden flex items-center justify-center'
        id='home'
      >
        <div className='hidden md:block'>
          <img
            src='/bgimagememe.png'
            fetchpriority='high'
            loading='eager'
            decoding='async'
            alt='main-bg'
            className='absolute top-0 left-0 w-full h-full object-fill object-left sm:object-center lg:object-center -z-1'
          />
        </div>
        <div className='block md:hidden'>
          <img
            src='/bgimagemobile.png'
            fetchpriority='high'
            loading='eager'
            decoding='async'
            alt='main-bg'
            className='absolute top-0 left-0 w-full h-full object-fill object-left sm:object-center lg:object-center -z-1'
          />
        </div>

        <div className='z-10 relative text-center text-white flex w-full h-full flex-col justify-center items-center'>
          <div className='h-[80%] flex flex-col justify-center items-center mt-12'>
            <h1 className='text-[40px] leading-[54px] uppercase lg:text-6xl sm:text-6xl font-darumadrop mb-4 text-stroke-dense'>
              watching gols & taking betts{" "}
            </h1>
            <h2 className='text-[64px] leading-[64px] uppercase sm:text-8xl lg:text-8xl font-darumadrop mb-6 text-stroke-dense'>
              LiKE EVERY Loyel FEN{" "}
            </h2>
            <div className='flex flex-col items-center gap-4 lg:flex-row sm:flex-row font-primary justify-center my-4 w-[70%] mx-auto'>
              <button
                className='bg-[#00BDE5] w-[77%] text-[22px] sm:text-2xl lg:text-2xl text-stroke-thick py-4 rounded-lg'
                onClick={handleBuynowclick}
              >
                BUY NOW
              </button>
              <button
                className='bg-[#00BDE5] w-[77%] text-[22px] sm:text-2xl lg:text-2xl text-stroke-thick py-4  rounded-lg'
                onClick={handleNavClick}
              >
                START BETTING
              </button>
            </div>
            <div className='flex justify-center items-center mt-15'>
              {" "}
              <div className='flex items-center justify-center gap-8 sm:gap-16 lg:gap-16'>
                <img
                  src='/telegramicon.png'
                  className='h-12 w-12 sm:w-16 sm:h-16 lg:w-16 lg:h-16 cursor-pointer'
                  onClick={() => handleIconClick("telegram")}
                />
                <img
                  src='/dexscreenericon.png'
                  className='h-12 w-12 sm:w-16 sm:h-16 lg:w-16 lg:h-16 cursor-pointer'
                  onClick={() => handleIconClick("dexscreener")}
                />
                <img
                  src='/twittericon.png'
                  className='h-12 w-12 sm:w-16 sm:h-16 lg:w-16 lg:h-16 cursor-pointer'
                  onClick={() => handleIconClick("twitter")}
                />
              </div>
            </div>
          </div>

          <div className='flex justify-around items-center my-auto w-full '>
            <div
              className='relative cursor-pointer'
              onClick={() => onCopyText(TOKEN_ADDRESS)}
            >
              {isCopied && (
                <p className='text-primary absolute top-[-25px] z-50 left-[45vw] sm:left-[-14px] lg:left-[-14px]'>
                  <span className='px-2 py-1 bg-green rounded-md text-white text-xs'>
                    Copied
                  </span>
                </p>
              )}
              <p className='font-darumadrop text-[14px] sm:text-3xl lg:text-3xl sm:text-thick-stroke lg:text-thick-stroke'>
                CA:
                {TOKEN_ADDRESS}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='relative w-screen min-h-screen overflow-hidden pt-32'>
        <img
          src='/Football-Stadium-Bg.png'
          fetchpriority='auto'
          loading='eager'
          decoding='async'
          alt='main-bg'
          className='absolute top-0 left-0 w-full h-full object-cover object-center -z-1'
        />

        <div className=' flex items-center justify-center mt-0' id='matches'>
          {selectedNav === "euro" &&
            (matches ? (
              <TabComponent
                cardData={matches}
                contract={contract}
                account={account}
                balance={balance}
                token={token}
                approved={approved}
                setApproved={setApproved}
                connectWallet={connectWallet}
                jackpotBets={bets}
                minstake={minstake}
                maxstake={maxstake}
                myMatches={myMatches}
              />
            ) : null)}
          {selectedNav === "copa" && <CopaCupComponent />}
        </div>
      </section>
    </div>
  );
}

export default Home;
