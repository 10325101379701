import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import Home from "./components/Home";
import Profile from "./components/Profile";
import CreateMatch from "./components/CreateMatch";

import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";
import ABI from "./contracts/bet_abi.json";
import TOKEN_ABI from "./contracts/token_abi.json";

import MatchCard from "./components/MatchCard";
import Header, { POSSIBLE_NAVIGATIONS } from "./components/Header";
import { CONTRACT_ADDRESS, TOKEN_ADDRESS } from "./constants";

const CONTRACT_ABI = ABI;
const BASE_CHAIN_ID = "0x2105";

function App() {
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [token, setToken] = useState(null);
  const [balance, setBalance] = useState(0);
  const [providerRequested, setProviderRequested] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [approved, setApproved] = useState(false);
  const [selectedNav, setSelectedNav] = useState(POSSIBLE_NAVIGATIONS.EURO);
  const [myMatches, setMyMatches] = useState([]);
  const [jackpotmatch, setJackpot] = useState([]);
  const [matches, setMatches] = useState([]);
  const [minstake, setminStake] = useState([]);
  const [maxstake, setmaxStake] = useState([]);
  const baseurls = [
    // "https://base-mainnet.g.alchemy.com/v2/vYEPg0kj38h6xLvv7rbkrLOVZIIoYmLM",
    // // "https://base-mainnet.g.alchemy.com/v2/HhO17jtpvGji6LT5YjcovOfN2AJmB8DD",
    // "https://base-rpc.publicnode.com",
    // "https://1rpc.io/3UoCgsBeZivPdrNDe/base",
    // "https://1rpc.io/PcGbxwMG9PnczCUV/base",
    // "https://1rpc.io/5ffotDbrcikJWA3PU/base",
    // "https://1rpc.io/4AVKAe6pcTHPNDDa7/base"
    "https://base-mainnet.core.chainstack.com/a6591a3bf42639a349d77efdf24d6dd6",
    "https://base-mainnet.core.chainstack.com/a6591a3bf42639a349d77efdf24d6dd6",
    "https://base-mainnet.core.chainstack.com/a6591a3bf42639a349d77efdf24d6dd6",
    "https://base-mainnet.core.chainstack.com/a6591a3bf42639a349d77efdf24d6dd6",
  ];

  useEffect(() => {
    async function checkWalletConnection() {
      const randomUrl =
        baseurls.length !== 1
          ? baseurls[Math.floor(Math.random() * (baseurls.length - 1) + 1)]
          : baseurls[0];
      const base = new Web3(randomUrl);
      const contractInstance = new base.eth.Contract(
        CONTRACT_ABI,
        CONTRACT_ADDRESS
      );
      setContract(contractInstance);

      const tokenInstance = new base.eth.Contract(TOKEN_ABI, TOKEN_ADDRESS);
      setToken(tokenInstance);

      const isDisconnected =
        localStorage.getItem("walletDisconnected") === "true";
      if (isDisconnected) {
        return; // Do not auto-connect if the wallet was disconnected by the user
      }

      const provider = await detectEthereumProvider();
      if (provider) {
        const web3 = new Web3(provider);
        const contractInstance = new web3.eth.Contract(
          CONTRACT_ABI,
          CONTRACT_ADDRESS
        );
        setContract(contractInstance);

        const tokenInstance = new web3.eth.Contract(TOKEN_ABI, TOKEN_ADDRESS);
        setToken(tokenInstance);
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          setIsWalletConnected(true);

          const networkId = await web3.eth.net.getId();
          if (networkId !== parseInt(BASE_CHAIN_ID, 16)) {
            try {
              setProviderRequested(true);
              await provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: BASE_CHAIN_ID }],
              });
            } catch (switchError) {
              if (switchError.code === 4902) {
                try {
                  await provider.request({
                    method: "wallet_addEthereumChain",
                    params: [
                      {
                        chainId: BASE_CHAIN_ID,
                        chainName: "Base Chain",
                        nativeCurrency: {
                          name: "Base Token",
                          symbol: "ETH",
                          decimals: 18,
                        },
                        rpcUrls: ["https://base-rpc.publicnode.com/"],
                        blockExplorerUrls: ["https://basescan.org"],
                      },
                    ],
                  });
                } catch (addError) {
                  console.error("Error adding Base chain:", addError);
                }
              } else {
                console.error("Error switching to Base chain:", switchError);
              }
            }
          }

          setProviderRequested(false);

          const balance = await tokenInstance.methods
            .balanceOf(accounts[0])
            .call();
          setBalance(Number(Web3.utils.fromWei(balance, "ether")).toFixed(0));

          const isApproved = false;
          if (isApproved) {
            setApproved(true);
          } else {
            const approve = await tokenInstance.methods
              .allowance(accounts[0], CONTRACT_ADDRESS)
              .call();
            console.log("approve", approve);
            if (Number(approve) >= 10 ** 27) {
              setApproved(true);
              localStorage.setItem("walletApproved", "true");
            } else {
              setApproved(false);
              localStorage.setItem("walletApproved", "false");
            }
          }
        }
      }
    }

    checkWalletConnection();
  }, []);

  async function connectWallet() {
    localStorage.removeItem("walletDisconnected");
    const provider = await detectEthereumProvider();
    if (provider) {
      const web3 = new Web3(provider);

      const contractInstance = new web3.eth.Contract(
        CONTRACT_ABI,
        CONTRACT_ADDRESS
      );
      setContract(contractInstance);

      const tokenInstance = new web3.eth.Contract(TOKEN_ABI, TOKEN_ADDRESS);
      setToken(tokenInstance);

      const accounts = await web3.eth.requestAccounts();
      setAccount(accounts[0]);
      const networkId = await web3.eth.net.getId();
      if (networkId !== parseInt(BASE_CHAIN_ID, 16)) {
        try {
          setProviderRequested(true);
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: BASE_CHAIN_ID }],
          });
        } catch (switchError) {
          if (switchError.code === 4902) {
            try {
              await provider.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: BASE_CHAIN_ID,
                    chainName: "Base Chain",
                    nativeCurrency: {
                      name: "Base Token",
                      symbol: "ETH",
                      decimals: 18,
                    },
                    rpcUrls: ["https://mainnet.base.org"],
                    blockExplorerUrls: ["https://basescan.org"],
                  },
                ],
              });
            } catch (addError) {
              console.error("Error adding Base chain:", addError);
            }
          } else {
            console.error("Error switching to Base chain:", switchError);
          }
        }
      }

      setProviderRequested(false);

      const balance = await token.methods.balanceOf(accounts[0]).call();
      setBalance(Web3.utils.fromWei(balance, "ether"));

      const isApproved = false;
      if (isApproved) {
        setApproved(true);
      } else {
        const approve = await token.methods
          .allowance(accounts[0], CONTRACT_ADDRESS)
          .call();
        console.log({ approve });
        if (Number(approve) > 10 ** 27) {
          setApproved(true);
          localStorage.setItem("walletApproved", "true");
        } else {
          setApproved(false);
          localStorage.setItem("walletApproved", "false");
        }
      }
    } else {
      console.error("Please install MetaMask!");
    }
  }

  const handleDisconnect = () => {
    setAccount(null);
    setIsWalletConnected(false);
    // setContract(null);
    // setToken(null);
    setBalance(0);
    localStorage.setItem("walletDisconnected", "true");
    localStorage.setItem("walletApproved", "false");
    setApproved(false);
  };

  useEffect(() => {
    async function fetchMatches() {
      const randomUrl1 = baseurls[0];
      const randomUrl2 = baseurls[1];
      const randomUrl3 = baseurls[2];
      const randomUrl4 = baseurls[3];
      const base1 = new Web3(randomUrl1);
      const base2 = new Web3(randomUrl2);
      const base3 = new Web3(randomUrl3);
      const base4 = new Web3(randomUrl4);
      const contractInstance1 = new base1.eth.Contract(
        CONTRACT_ABI,
        CONTRACT_ADDRESS
      );
      const contractInstance2 = new base2.eth.Contract(
        CONTRACT_ABI,
        CONTRACT_ADDRESS
      );
      const contractInstance3 = new base3.eth.Contract(
        CONTRACT_ABI,
        CONTRACT_ADDRESS
      );
      const contractInstance4 = new base4.eth.Contract(
        CONTRACT_ABI,
        CONTRACT_ADDRESS
      );

      if (contractInstance1) {
        try {
          const matchCount = await contractInstance1.methods
            .matchCount()
            .call();
          const maxStake = await contractInstance1.methods._maxStake().call();
          const minStake = await contractInstance1.methods._minStake().call();
          setminStake(Number(minStake));
          setmaxStake(Number(maxStake));
          // setminStake(10000);
          // setmaxStake(100000);
          console.log("matchCount:", matchCount);
          const matchesList = [];
          const mymatchesList = [];

          const matchPromises = [];

          for (let i = 1; i <= matchCount; i++) {
            matchPromises.push(contractInstance1.methods.matches(i).call());
          }

          function getIndices(a) {
            let index1, index2;

            if (a === 0) {
              index1 = 1;
              index2 = 2;
            } else if (a === 1) {
              index1 = 0;
              index2 = 2;
            } else if (a === 2) {
              index1 = 0;
              index2 = 1;
            } else {
              throw new Error("a must be 0, 1, or 2");
            }

            return { index1, index2 };
          }

          const matchResults = await Promise.all(matchPromises);

          const normalResults = await Promise.all(
            matchResults.map(async (match, index) => {
              const matchId = index + 1;
              const options = await contractInstance2.methods
                .getAllOptionsForMatch(matchId)
                .call();
              const drawIndex = options.findIndex(
                (option) => option?.toLowerCase() === "draw"
              );

              if (options.length === 3 && drawIndex > -1) {
                match["matchId"] = matchId;
                return match;
              } else {
                return null;
              }
            })
          );

          // Filter out the null values
          const filteredResults = normalResults.filter(
            (match) => match !== null
          );

          const stakersPromises = filteredResults.map(async (match, index) => {
            const matchId = match.matchId;
            const options = await contractInstance4.methods
              .getAllOptionsForMatch(matchId)
              .call();
            const drawindex = options.findIndex(
              (option) => option?.toLowerCase() === "draw"
            );

            if (options.length === 3 && drawindex > -1) {
              const { index1, index2 } = getIndices(drawindex);
              return Promise.all([
                options[index1],
                contractInstance1.methods.getStakers(matchId, index1).call(),
                options[index2],
                contractInstance2.methods.getStakers(matchId, index2).call(),
                options[drawindex],
                contractInstance3.methods.getStakers(matchId, drawindex).call(),
              ]);
            }
          });

          const stakersResults = await Promise.all(stakersPromises);
          console.log("stakersResults", stakersResults);

          filteredResults.forEach((match, index) => {
            // const matchId = index + 1;
            match["indexvalue"] = match.matchId;
            match["my_stake_flag"] = false;
            match["winner"] = "";

            const [
              teamA,
              stakersAResult,
              teamB,
              stakersBResult,
              draw,
              stakersDrawResult,
            ] = stakersResults[index];
            match["teamA"] = teamA;
            match["teamB"] = teamB;
            match["totalStakeTeamA"] = stakersAResult[1].reduce(
              (accumulator, currentValue) => accumulator + Number(currentValue),
              0
            );
            match["totalStakeTeamB"] = stakersBResult[1].reduce(
              (accumulator, currentValue) => accumulator + Number(currentValue),
              0
            );
            match["totalStakeDraw"] = stakersDrawResult[1].reduce(
              (accumulator, currentValue) => accumulator + Number(currentValue),
              0
            );

            const stakersA = stakersAResult[0];
            match["num_stakers_A"] = stakersA.length;
            const stakedAmountsA = stakersAResult[1];
            const stakersB = stakersBResult[0];
            match["num_stakers_B"] = stakersB.length;
            const stakedAmountsB = stakersBResult[1];
            const stakersDraw = stakersDrawResult[0];
            match["num_stakers_Draw"] = stakersDraw.length;
            const stakedAmountsDraw = stakersDrawResult[1];
            // also add for the draw "num_stakers_draw" @puneet

            const indexA = stakersA.findIndex(
              (address) => address?.toLowerCase() === account?.toLowerCase()
            );
            const indexB = stakersB.findIndex(
              (address) => address?.toLowerCase() === account?.toLowerCase()
            );
            const indexDraw = stakersDraw.findIndex(
              (address) => address?.toLowerCase() === account?.toLowerCase()
            );

            const totalStakedByAccount = ({
              stakers,
              stakedAmounts,
              my_staked_amount,
            }) => {
              const res = stakers.reduce((acc, staker, index) => {
                if (staker?.toLowerCase() === account?.toLowerCase()) {
                  acc += Number(stakedAmounts[index]);
                }
                return acc;
              }, 0);

              match[my_staked_amount] = res || 0;
            };

            totalStakedByAccount({
              stakers: stakersA,
              stakedAmounts: stakedAmountsA,
              my_staked_amount: "my_staked_amount_A",
            });
            totalStakedByAccount({
              stakers: stakersB,
              stakedAmounts: stakedAmountsB,
              my_staked_amount: "my_staked_amount_B",
            });
            totalStakedByAccount({
              stakers: stakersDraw,
              stakedAmounts: stakedAmountsDraw,
              my_staked_amount: "my_staked_amount_Draw",
            });

            if (indexA > -1 || indexB > -1 || indexDraw > -1) {
              match["my_stake_flag"] = true;
              // also add for the draw "my_staked_amount_draw" @puneet
              mymatchesList.push(match);
            }
            matchesList.push(match);
          });

          //// Euro Cup
          const jackpotmatchid = 2;
          const jackpotmatch = await contractInstance1.methods
            .matches(jackpotmatchid)
            .call();
          jackpotmatch["matchId"] = jackpotmatchid;
          const jackpotoptions = await contractInstance2.methods
            .getAllOptionsForMatch(jackpotmatchid)
            .call();
          const jackpotResults = jackpotmatch;
          const jackpotRes = [];
          jackpotoptions.forEach(async (option, index) => {
            const res = await contractInstance4.methods
              .getStakers(jackpotmatchid, index)
              .call();
            const optionresult = {
              country: option,
              totalBets: res[0].length,
              betAmount: res[1].reduce(
                (accumulator, currentValue) =>
                  accumulator + Number(currentValue),
                0
              ),
              matchId: jackpotmatchid,
              country_val: index,
            };
            jackpotRes.push(optionresult);
          });

          jackpotResults["bets"] = jackpotRes;
          // const totalJackpotAmount = jackpotRes?.reduce((accumulator, currentEl) => {
          //   console.log({currentEl})
          //   return accumulator + Number(currentEl?.betAmount)
          // }, 0);
          // jackpotResults['totalJackpotAmount'] = totalJackpotAmount;
          console.log("hey bro");
          console.log({ jackpotResults });

          setMatches(matchesList);
          setMyMatches(mymatchesList);
          setJackpot(jackpotResults);
        } catch (error) {
          if (error.code === 429) {
            alert("Too many RPC requests. Please try again later.");
          } else {
            console.error("Error fetching matches:", error);
          }
        }
      }
    }

    fetchMatches();
  }, [account]);

  return (
    <Router>
      <div className='font-primary text-white'>
        {/* <div className=' flex  justify-end items-center'>
          <nav>
            <Link to='/'>Home</Link>
            <Link to='/matches'>Matches</Link>
            <Link to='/bet'>Bet</Link>
          </nav>
          {account && (
            <button
              class='bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline'
              onclick="window.location.href='/profile'"
            >
              My Profile
            </button>
          )}
          <ConnectButton
            handleDisconnect={handleDisconnect}
            handleOnClick={connectWallet}
            account={account}
            balance={balance}
            />
            </div> */}
        <Header
          handleDisconnect={handleDisconnect}
          connectWallet={connectWallet}
          account={account}
          balance={balance}
          selectedNav={selectedNav}
          setSelectedNav={setSelectedNav}
        />
        <Routes>
          <Route
            path='/'
            element={
              <Home
                matches={matches}
                connectWallet={connectWallet}
                account={account}
                selectedNav={selectedNav}
                contract={contract}
                token={token}
                approved={approved}
                setApproved={setApproved}
                balance={balance}
                minstake={minstake}
                maxstake={maxstake}
                myMatches={myMatches}
                bets={jackpotmatch}
              />
            }
          />
          {/* <Route path='/matches' element={<Matches contract={contract} account={account} token={token} approved={approved} setApproved={setApproved}/>} />
          <Route
            path='/bet'
            element={
              <Bet account={account} contract={contract} token={token} />
            }
          /> */}
          {/* <Route
            path='/profile'
            element={
              <Profile
                myMatches={myMatches}
                account={account}
                contract={contract}
                token={token}
                balance={balance}
                approved={approved}
                setApproved={setApproved}
                minstake={minstake}
                maxstake={maxstake}
              />
            }
          /> */}

          <Route
            path='/create'
            element={<CreateMatch contract={contract} account={account} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
