import React, { useState } from "react";
import Web3 from "web3";
import ABI from "../contracts/bet_abi.json"; // Adjust the path as necessary
import { CONTRACT_ADDRESS } from "../constants"; // Adjust the path as necessary
import matches from "../csvjson.json"
const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");

const CreateMatch = (contract,account) => {
  const [startTime, setStartTime] = useState("");
  const [message, setMessage] = useState("");
  const [selectedMatch, setSelectedMatch] = useState('');
  
//   const contract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('select',selectedMatch)
    try {
      const match = matches[selectedMatch]
      const options = [match['Team A'],match['Team B'],"draw"]
      const description = match['Description']
      const startTime = match['StartTime']
      console.log(options.split(",").map(option => option.trim()))
      console.log(description)
      console.log(startTime)
      await contract.methods
        .createMatch(
          options.split(",").map(option => option.trim()),
          description,
          startTime
        )
        .send({ from: account });
      setMessage("Match created successfully!");
    } catch (error) {
      console.error("Error creating match:", error);
      setMessage("Error creating match. See console for details.");
    }
  };

  return (
    <div class="flex h-screen">
          <div class="m-auto">
      <h2>Create Match</h2>
      

      <form onSubmit={handleSubmit} className="w-full max-w-sm">
        <div className="mb-4">
          <label htmlFor="match" className="block text-gray-700 text-sm font-bold mb-2">
            Select Match:
          </label>
          <div className="relative">
            <select
              id="match"
              value={selectedMatch}
              onChange={(e) => setSelectedMatch(e.target.value)}
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-black"
            >
              <option value="">Select a match</option>
              {matches.map((match, index) => (
                <option key={index} value={match}>
                  {index+1}-{match["Team A"]} vs {match["Team B"]}
                  {console.log(match)}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M7 10l5 5 5-5H7z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
      {message && <p>{message}</p>}
    </div>
    </div>

  );
};

export default CreateMatch;
