import React from "react";
import CardGrid, { calculateTotalWinnings } from "./Cardgrid";

const Profile = ({
  account,
  balance,
  token,
  myMatches,
  contract,
  approved,
  setApproved,
  minstake,
  maxstake,
}) => {
  const totalWinnings = calculateTotalWinnings({ matches: myMatches });

  return (
    <div className=' '>
      {/* <section
        className='relative w-screen min-h-screen overflow-hidden pt-32 '
        id='matches'
      > */}
      {/* <img
          src='/Football-Stadium-Bg.png'
          fetchpriority='auto'
          loading='eager'
          decoding='async'
          alt='main-bg'
          className='absolute top-0 left-0 w-full h-full object-cover object-center -z-1'
        /> */}
      {/* main div for profile page */}
      {/* <div className='flex justify-center items-center w-[83vw] mx-auto'>
        <div className='bg-[#0B1428] opacity-75 rounded-xl p-4 flex flex-col-reverse sm:flex-row justify-between items-center w-full'>
          <div className='flex flex-col sm:flex-row justify-center items-center mx-2 w-[100%] sm:w-auto sm:space-x-12'>
            <div className=' flex flex-row-reverse sm:flex-col justify-between sm:justify-center items-center sm:items-start w-[100%] sm:w-auto '>
              <p className='text-xl'>FENS {totalWinnings.toFixed()}</p>
              <p className='text-gray font-secondary font-semibold '>
                {" "}
                Total Winnings{" "}
              </p>
            </div>
            <div className=' flex flex-row-reverse sm:flex-col justify-between sm:justify-center items-center sm:items-start w-[100%] sm:w-auto '>
              <p className='text-xl'>FENS {Number(balance).toFixed()}</p>
              <p className='text-gray font-secondary font-semibold '>
                Belence{" "}
              </p>
            </div>
          </div>
          <div className='flex justify-center items-center mx-2 w-[100%] sm:w-auto'>
            <div className=' flex flex-row-reverse sm:flex-col justify-between sm:justify-center items-center sm:items-start w-[100%] sm:w-auto '>
              <p className='text-xl'>
                {account ? (
                  <span className='sm:pr-3'>
                    {account?.slice(0, 4) +
                      "..." +
                      account?.slice(account.length - 4)}
                  </span>
                ) : (
                  "-"
                )}
              </p>
              <p className='text-gray font-secondary font-semibold '>
                {" "}
                Connected wallet{" "}
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {myMatches ? (
        <div>
          <CardGrid
            cards={myMatches}
            contract={contract}
            account={account}
            token={token}
            approved={approved}
            setApproved={setApproved}
            fullFeatures={true}
            balance={balance}
            isProfile={true}
            minstake={minstake}
            maxstake={maxstake}
          />
        </div>
      ) : null}
      {/* </section> */}
    </div>
  );
};

export default Profile;
